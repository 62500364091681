import React, { useEffect, useState } from 'react'
import {
  makeStyles,
  Typography,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TablePagination,
  CircularProgress,
  Fade,
} from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    width: '100%',
    // marginRight: 20
  },
  title: {    
    fontSize: '14px',
    fontWeight: 'bold',
  },
  header: {
    color: 'rgba(255, 255, 255, 1.0)',
    fontWeight: 700,
  },
  details: {
    alignItems: 'right'
  },
  column: {
    flexBasis: '50%'
  },
  total: {
    fontWeight: 700,
    color: 'rgba(92, 92, 92)',
    flexBasis: '50%',
  },
  totalDark: {
    fontWeight: 700,
    color: 'rgba(255, 255, 255)',
    flexBasis: '50%',
  },
}))

const getTableHeaders = (roasFlag) => {
  if(roasFlag === 1) return [
    {name: 'Type', alignment: 'left', sortBy: 'Label'},
    {name: 'Spend', alignment: 'right', sortBy: 'Cost'},
    {name: 'Sales', alignment: 'right', sortBy: 'Sales'},
    {name: 'Mailed', alignment: 'right', sortBy: 'Mailed'},
    {name: 'Responses', alignment: 'right', sortBy: 'Responses'},
    {name: 'Response Rate', alignment: 'right', sortBy: 'Res_Rate'},
    {name: 'Holdout Count', alignment: 'right', sortBy: 'HO_Count'},
    {name: 'Holdout Sales', alignment: 'right', sortBy: 'HO_Sales'},
    {name: 'Holdout Responses', alignment: 'right', sortBy: 'HO_Resp'},
    {name: 'Holdout Response Rate', alignment: 'right', sortBy: 'HO_Res_Rate'},
    {name: 'Inc Response Rate', alignment: 'right', sortBy: 'ADJ_Res_Rate'},
    {name: 'Avg Ticket', alignment: 'right', sortBy: 'Avg_Ticket'},
    {name: 'ROAS', alignment: 'right', sortBy: 'Label'},
    {name: 'Inc ROAS', alignment: 'right', sortBy: 'Label',},
  ]
  else return [
    {name: 'Type', alignment: 'left', sortBy: 'Label'},
    {name: 'Spend', alignment: 'right', sortBy: 'Cost'},
    {name: 'Sales', alignment: 'right', sortBy: 'Sales'},
    {name: 'Mailed', alignment: 'right', sortBy: 'Mailed'},
    {name: 'Responses', alignment: 'right', sortBy: 'Responses'},
    {name: 'Response Rate', alignment: 'right', sortBy: 'Res_Rate'},
    {name: 'Holdout Sales', alignment: 'right', sortBy: 'HO_Count'},
    {name: 'Holdout Count', alignment: 'right', sortBy: 'HO_Res_Rate'},
    {name: 'Holdout Responses', alignment: 'right', sortBy: 'ADJ_Res_Rate'},
    {name: 'Holdout Response Rate', alignment: 'right', sortBy: 'Avg_Ticket'},
    {name: 'Inc Response Rate', alignment: 'right', sortBy: 'Label'},
    {name: 'Avg Ticket', alignment: 'right', sortBy: 'Label',},
  ]
}

const getTotalData = data => {
  let totalData = data.map(item => item.total).reduce((a,b) => {
    for(const prop in b) {
      if(a.hasOwnProperty(prop) && prop !== 'Label') a[prop] += b[prop]
      else a[prop] = b[prop]
    }
    return a
  }, {})

  totalData['Res_Rate'] = totalData.Mailed > 0 ? totalData.Responses /  totalData.Mailed : 0
  totalData['HO_Res_Rate'] = totalData.HO_Count > 0 ? totalData.HO_Resp / totalData.HO_Count : 0
  totalData['ADJ_Res_Rate'] = totalData.Res_Rate > totalData.HO_Res_Rate ? totalData.Res_Rate - totalData.HO_Res_Rate : 0
  totalData['Avg_Ticket'] = totalData.Responses > 0 ? totalData.Sales / totalData.Responses : 0
  totalData['ROAS'] = totalData.Cost > 0 ? totalData.Sales / totalData.Cost : 0
  totalData['ADJ_ROAS'] = (totalData.Responses / totalData.Mailed - (totalData.HO_Resp / totalData.HO_Count)) > 0 ? (totalData.Sales / (totalData.Responses / totalData.Mailed)) * (totalData.Responses / totalData.Mailed - (totalData.HO_Resp / totalData.HO_Count)) / totalData.Cost : 0

  return totalData
}

export default function NewCatsTable({data, roasFlag, bUnit}) {
  const classes = useStyles()
  const [dataOrdered, setDataOrdered] = useState(null)
  const [totalCategoryData, setTotalCategoryData] = useState(null)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(data.length > 20 ? Math.round((data.length / 4) / 10) * 5 : Math.round((data.length/5) * 5))
  const tableHeaders = getTableHeaders(roasFlag)

  useEffect(() => {
    if(data.length > 0) {
      console.log(data)
      const totals = getTotalData(data)
      setTotalCategoryData(totals)
    } else setTotalCategoryData([])
  }, [data])

  const handleChangePage = (e, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = e => {
    setPage(0)
    setRowsPerPage(parseInt(e.target.value))
  }

  if(!totalCategoryData) return <CircularProgress />

  return (
    <div>      
      <div style={{display: 'flex', alignItems: 'center'}}>            
            <Typography className={classes.title}>PRODUCT CATEGORY</Typography>
            <Fade in={true} timeout={2000}>
              <div style={roasFlag && bUnit !== 3 ? {textAlign: 'center'} : {display: 'none'}}>
                <div style={{margin: '1em', display: 'inline-flex', alignItems: 'center'}}>
                  <WarningIcon style={{color: '#de9f10'}}/>
                  <Typography align="center" variant="caption" style={{marginLeft: '5px', fontSize: '15px'}}>
                    Due to the low holdout quantity, <span style={{color: "#d13e34", fontWeight: 'bold'}}>the incremental metrics may not be statistically significant.</span>
                  </Typography>
                </div>
              </div>
            </Fade>
          </div>
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead style={{backgroundColor: '#0e85d3'}}>
            <TableRow>
              {tableHeaders.map(({name, alignment, sortBy}, i) => (
                <TableCell className={classes.header} align={alignment} key={`header-${i}`}>
                  <Typography variant="caption" className={classes.header}>{name}</Typography>
                </TableCell>
              ))}
            </TableRow>
            

          </TableHead>
          <TableBody>
            {data. length > 10 ?
              data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(({total}, i) => (
                <TableRow key={`${total['Label']}-${i}`} style={{height: 75}}>
                  <TableCell>
                    <Typography variant="caption">{total['Label'].toUpperCase()}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">${Math.round(total['Cost']).toLocaleString('en')}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">${Math.round(total['Sales']).toLocaleString('en')}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">{Math.round(total['Mailed']).toLocaleString('en')}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">{Math.round(total['Responses']).toLocaleString('en')}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">{total['Res_Rate'] > 0 ? (total['Res_Rate'] * 100).toFixed('2') : 0.00}%</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">{roasFlag ? Math.round(total['HO_Count']).toLocaleString('en') : '$' + Math.round(total['HO_Sales']).toLocaleString('en')}</Typography>
                  </TableCell>
                  <TableCell align="right" style={{display: `${roasFlag ? "" : "none"}`}}>
                    <Typography variant="caption">{'$' + Math.round(total['HO_Sales']).toLocaleString('en')}</Typography>
                  </TableCell>
                  <TableCell align="right" style={{display: `${roasFlag ? "" : "none"}`}}>
                    <Typography variant="caption">{Math.round(total['HO_Resp']).toLocaleString('en')}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">{roasFlag ? total['HO_Res_Rate'] > 0 ? (total['HO_Res_Rate'] * 100).toFixed(2) + '%' : '0.00%' : Math.round(total['HO_Count']).toLocaleString('en')}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">{roasFlag ? total['ADJ_Res_Rate'] > 0 ? (total['ADJ_Res_Rate'] * 100).toFixed(2) + '%' : '0.00%' : Math.round(total['HO_Resp']).toLocaleString('en')}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">{roasFlag ? total['Responses'] > 0 ? "$" + Math.round(total['Sales']/total['Responses']).toLocaleString('en') : '$0' : (total['HO_Res_Rate'] * 100).toFixed(2) + '%'}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">{roasFlag ? total['ROAS'] > 0 ? '$' + (total['ROAS']).toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2}) : '$0.00' : total['ADJ_Res_Rate'] > 0 ? (total['ADJ_Res_Rate'] * 100).toFixed(2) + '%' : '0.00%'}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">{roasFlag ? total['ADJ_ROAS'] > 0 ? '$' + (total['ADJ_ROAS']).toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2}) : '$0.00' : total['Responses'] > 0 ? '$' + Math.round(total['Sales']/total['Responses']).toLocaleString('en') : "$0"}</Typography>
                  </TableCell>
                </TableRow>
              ))
            :
              data.map(({total}, i) => (
                <TableRow key={`${total['Label']}-${i}`} style={{height: 75}}>
                  <TableCell>
                    <Typography variant="caption">{total['Label'].toUpperCase()}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">${Math.round(total['Cost']).toLocaleString('en')}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">${Math.round(total['Sales']).toLocaleString('en')}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">{Math.round(total['Mailed']).toLocaleString('en')}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">{Math.round(total['Responses']).toLocaleString('en')}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">{total['Res_Rate'] > 0 ? (total['Res_Rate'] * 100).toFixed('2') : 0.00}%</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">{roasFlag ? Math.round(total['HO_Count']).toLocaleString('en') : '$' + Math.round(total['HO_Sales']).toLocaleString('en')}</Typography>
                  </TableCell>
                  <TableCell align="right" style={{display: `${roasFlag ? "" : "none"}`}}>
                    <Typography variant="caption">{'$' + Math.round(total['HO_Sales']).toLocaleString('en')}</Typography>
                  </TableCell>
                  <TableCell align="right" style={{display: `${roasFlag ? "" : "none"}`}}>
                    <Typography variant="caption">{Math.round(total['HO_Resp']).toLocaleString('en')}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">{roasFlag ? total['HO_Res_Rate'] > 0 ? (total['HO_Res_Rate'] * 100).toFixed(2) + '%' : '0.00%' : Math.round(total['HO_Count']).toLocaleString('en')}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">{roasFlag ? total['ADJ_Res_Rate'] > 0 ? (total['ADJ_Res_Rate'] * 100).toFixed(2) + '%' : '0.00%' : Math.round(total['HO_Resp']).toLocaleString('en')}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">{roasFlag ? total['Responses'] > 0 ? "$" + Math.round(total['Sales']/total['Responses']).toLocaleString('en') : '$0' : (total['HO_Res_Rate'] * 100).toFixed(2) + "%"}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">{roasFlag ? total['ROAS'] > 0 ? '$' + (total['ROAS']).toFixed(2) : '$0.00' : total['ADJ_Res_Rate'] > 0 ? (total['ADJ_Res_Rate'] * 100).toFixed(2) + '%' : '0.00%'}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">{roasFlag ? total['ADJ_ROAS'] > 0 ? '$' + (total['ADJ_ROAS']).toLocaleString('en', {maximumFractionDigits: 2}) : "$0" : total['Responses'] > 0 ? '$' + Math.round(total['Sales']/total['Responses']).toLocaleString('en') : 0}</Typography>
                  </TableCell>
                </TableRow>
              ))
            }
            <TableRow>            
              <TableCell><Typography variant="caption" className={classes.total}>TOTAL</Typography></TableCell>
              <TableCell align="right"><Typography variant="caption" className={classes.total}>${Object.keys(totalCategoryData).length > 0 ? Math.round(totalCategoryData.Cost).toLocaleString('en') : 0}</Typography></TableCell>
              <TableCell align="right"><Typography variant="caption" className={classes.total}>${Object.keys(totalCategoryData).length > 0 ? Math.round(totalCategoryData.Sales).toLocaleString('en') : 0}</Typography></TableCell>
              <TableCell align="right"><Typography variant="caption" className={classes.total}>{Object.keys(totalCategoryData).length > 0 ? Math.round(totalCategoryData.Mailed).toLocaleString('en') : 0}</Typography></TableCell>
              <TableCell align="right"><Typography variant="caption" className={classes.total}>{Object.keys(totalCategoryData).length > 0 ? Math.round(totalCategoryData.Responses).toLocaleString('en') : 0}</Typography></TableCell>
              <TableCell align="right"><Typography variant="caption" className={classes.total}>{Object.keys(totalCategoryData).length > 0 ? (totalCategoryData.Res_Rate * 100).toFixed(2) : 0}%</Typography></TableCell>
              <TableCell align="right"><Typography variant="caption" className={classes.total}>{Object.keys(totalCategoryData).length > 0 ? roasFlag ? Math.round(totalCategoryData.HO_Count).toLocaleString('en') : `$${Math.round(totalCategoryData.HO_Sales).toLocaleString('en')}` : 0}</Typography></TableCell>
              <TableCell align="right" style={{display: `${roasFlag ? "" : "none"}`}}><Typography variant="caption" className={classes.total}>{Object.keys(totalCategoryData).length > 0 ? `$${Math.round(totalCategoryData.HO_Sales).toLocaleString('en')}` : 0}</Typography></TableCell>
              <TableCell align="right" style={{display: `${roasFlag ? "" : "none"}`}}><Typography variant="caption" className={classes.total}>{Object.keys(totalCategoryData).length > 0 ? `${Math.round(totalCategoryData.HO_Resp).toLocaleString('en')}` : 0}</Typography></TableCell>
              <TableCell align="right"><Typography variant="caption" className={classes.total}>{Object.keys(totalCategoryData).length > 0 ? roasFlag ? `${(totalCategoryData.HO_Res_Rate * 100).toFixed(2)}%` : Math.round(totalCategoryData.HO_Count).toLocaleString('en') : 0}</Typography></TableCell>
              <TableCell align="right"><Typography variant="caption" className={classes.total}>{Object.keys(totalCategoryData).length > 0 ? roasFlag ? `${(totalCategoryData.ADJ_Res_Rate * 100).toFixed(2)}%` : Math.round(totalCategoryData.HO_Resp).toLocaleString('en') : 0}</Typography></TableCell>
              <TableCell align="right"><Typography variant="caption" className={classes.total}>{Object.keys(totalCategoryData).length > 0 ? roasFlag ? `$${Math.round(totalCategoryData.Avg_Ticket).toLocaleString('en')}` : `${(totalCategoryData.HO_Res_Rate * 100).toFixed(2)}%` : 0}</Typography></TableCell>
              <TableCell align="right"><Typography variant="caption" className={classes.total}>{Object.keys(totalCategoryData).length > 0 ? roasFlag ? `$${(totalCategoryData.ROAS).toFixed(2)}` : `${(totalCategoryData.ADJ_Res_Rate * 100).toFixed(2)}%` : 0}</Typography></TableCell>
              <TableCell align="right"><Typography variant="caption" className={classes.total}>{Object.keys(totalCategoryData).length > 0 ? roasFlag ? `$${(totalCategoryData.ADJ_ROAS).toFixed(2)}` : `$${Math.round(totalCategoryData.Avg_Ticket).toLocaleString('en')}` : 0}</Typography></TableCell>
            </TableRow>
            {console.log(data.length)}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={data.length > 20 ? [Math.round((data.length / 4) / 10) * 5, Math.round((data.length / 2) / 10) * 10, data.length] : [Math.round((data.length / 4) / 5) * 5, Math.ceil((data.length / 2) / 5) * 5, data.length]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          style={data.length > 10 ? {} : {display: 'none'}}
        />
      </Paper>
    </div>
  )
}
