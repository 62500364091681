import React, { useEffect, useState } from 'react'
import { 
  Typography,
  Select,
  MenuItem,
  withStyles,
  makeStyles,
  InputBase,   
  Checkbox,
  Tooltip,
  CircularProgress,
} from '@material-ui/core'
import Stats from '../roi/Stats'
import Cookies from 'js-cookie'
import moment from 'moment'
import CustProsTable from '../roi/CustProsTable'
import CategoriesTable from '../roi/CategoriesTable'
import ListTable from '../roi/ListTable'
import TrigLocationsTable from '../triggers/TrigLocationsTable'
import DayPicker from '../calendar/DayPicker'
import { RiUserSearchFill } from "react-icons/ri";
import RoiCharts from '../roi/RoiCharts'
import { InfoOutlined } from '@material-ui/icons'

const base_url = 'https://api.dev.lsdirect.com/smartdash/V1/index.cfm/'

const StyledInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    overflowX: 'auto',
    position: 'relative',
    padding: '2px 5px 2px 5px',
    border: '1px solid #767676',
    fontSize: 7,
    '&:focus': {
      border: '1px solid',
      borderRadius: 4,
      borderColor: '#0054c1',
      backgroundColor: 'transparent'
    }
  }
}))(InputBase)

const StyledTooltip = withStyles({
  tooltip: {    
    color: "#6d6d6d",
    backgroundColor: "#fff",
    borderRadius: "8px",
    border: "2px solid #25aae1"
  }
})(Tooltip)

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '17px',
    fontWeight: 'light',
    color: 'rgba(115, 115, 115, 0.9)',
    marginTop: '15px'
  },
  pageIcon: {
    fontSize: '15px',
    marginTop: '21px',
    marginRight: '2px',
  },
}))

export const DailyProspecting = () => {
  const initialState = {
  dates: {
    'startDate': Boolean(JSON.parse(localStorage.getItem('currentUser'))[0].max_date_dpros) && JSON.parse(localStorage.getItem('currentUser'))[0].max_date_dpros !== ''
      ? moment(JSON.parse(localStorage.getItem('currentUser'))[0].max_date_dpros) 
      : moment().startOf('year'), 
    'endDate': Boolean(JSON.parse(localStorage.getItem('currentUser'))[0].max_date_dpros) && JSON.parse(localStorage.getItem('currentUser'))[0].max_date_dpros !== '' 
      ? moment(JSON.parse(localStorage.getItem('currentUser'))[0].max_date_dpros) : moment() 
  }
}
  
  const token = Cookies.get('token')
  const classes = useStyles()
  const db = JSON.parse(localStorage.getItem('currentUser'))[0].db_owner
  const compID = JSON.parse(localStorage.getItem('currentUser'))[0].compIDs
  const corpID = JSON.parse(localStorage.getItem('currentUser'))[0].corpID
  const [dates, setDates] = useState(initialState.dates)
  const [dayRange, setDayRange] = useState(0)
  const [dailyProsData, setDailyProsData] = useState(null)
  const [selectedDailyProsData, setSelectedDailyProsData] = useState(null)
  const [marketData, setMarketData] = useState(null)
  const [selectedMarkets, setSelectedMarkets] = useState(['ALL'])
  const [selectedType, setSelectedType] = useState('')
  const [mailingType, selectedMailingType] = useState('completed')
  const [anchor, setAnchor] = useState(null)
  const [checked, setChecked] = useState([])  

  
  const getProspectingData = (datesObj, type) => {    
    let dailyProsData = []
    const request = Promise.all([
      fetch(`${base_url}/dprosROI?startDate=${datesObj.startDate.format('YYYY-MM-DD')}&endDate=${datesObj.endDate.format('YYYY-MM-DD')}`, {headers: {token: token}}),      
      fetch(`${base_url}/dprosROI/category?startDate=${datesObj.startDate.format('YYYY-MM-DD')}&endDate=${datesObj.endDate.format('YYYY-MM-DD')}`, {headers: {token: token}}),
      fetch(`${base_url}/dprosROI/listType?startDate=${datesObj.startDate.format('YYYY-MM-DD')}&endDate=${datesObj.endDate.format('YYYY-MM-DD')}`, {headers: {token: token}}),
      fetch(`${base_url}/dprosROI/weekly?startDate=${datesObj.startDate.format('YYYY-MM-DD')}&endDate=${datesObj.endDate.format('YYYY-MM-DD')}`, {headers: {token: token}}),
      fetch(`${base_url}/dprosROI/dashboard/${type}?startDate=${datesObj.startDate.format('YYYY-MM-DD')}&endDate=${datesObj.endDate.format('YYYY-MM-DD')}`, {headers: {token: token}}),
      fetch(`${base_url}/dprosROI/dashboard?startDate=${datesObj.startDate.format('YYYY-MM-DD')}&endDate=${datesObj.endDate.format('YYYY-MM-DD')}`, {headers: {token: token}}),      
    ])

    request.then(([response, response3, response4, response5, response6, response7]) => Promise.all([response.json(), response3.json(), response4.json(), response5.json(), response6.json(), response7.json()]))
    .then(([data, data3, data4, data5, data6, data7]) => {      
      if(data.length > 0) {        
        let dailyProsObj = {}
        dailyProsObj['Data_Category'] = data3        
        dailyProsObj['Data_Location'] = data7[0].Data_Location
        dailyProsObj['Data_ListType'] = data4
        dailyProsObj['Data_Overall'] = data[0].Data
        dailyProsObj['Data_Weekly'] = data5
        dailyProsObj['Label'] = 'ALL'
        dailyProsObj['Key'] = data6.length + 1
        dailyProsData.push(dailyProsObj)      

        setDailyProsData(dailyProsData.concat(data6))
        setSelectedDailyProsData(dailyProsData.filter(({Label}) => Label === 'ALL'))
        setDayRange(datesObj.endDate.diff(datesObj.startDate, 'days'))
        if(type && type !== selectedType) {
          setSelectedType(type)
          setChecked(dailyProsData.concat(data6).map(({Label}) => Label))
        } else {
          console.log('hello')
        }
      } else setSelectedDailyProsData([])
    })
  }

  useEffect(() => {
    getProspectingData(dates, 'market')
  }, [])

  const handleDateRange = (value, valName) => {
    let datesObj = {...dates}
    datesObj[valName] = value
    
    setDates(datesObj)
    getProspectingData(datesObj, selectedType)
  }

  const handleSliderDateChange = (values) => {
    let newDatesObj = {'startDate': moment(values[0]), 'endDate': moment(values[1])}

    setDates(newDatesObj)
    getProspectingData(newDatesObj, selectedType)
  }

  const handleDateClear = () => {
    getProspectingData(initialState.dates, selectedType)
  }
  
  const getStatsData = () => {
    let statsData = selectedDailyProsData.length > 0 
    ? selectedDailyProsData.map(({Data_Overall}) => Data_Overall[0]).reduce((a,b) => {
      for(const prop in b) {
        if(a.hasOwnProperty(prop)) a[prop] += b[prop]
        else a[prop] = b[prop]
      }
      return a
    }, {}) 
    : []    
    
    return statsData
  }

  const getTableData = (data) => {
    let metricData = []    

    if(Boolean(data[0])) {
      for(let i = 0; i < data.length; i++) {
        let metricObj = {}
        metricObj['label'] = data[i].Label
        metricObj['data'] = [data[i]]
        metricObj['total'] = data[i]
        metricData.push(metricObj)                
      }
    }
    return metricData  
  }

  const handleMailingTypeOpen = () => {
    console.log('hello')
  }
  
  const handleSelectedType = (e) => {
    const { value } = e.target
    
    setSelectedType(value)
    getProspectingData(dates, value)
  }

  const handleMarketChange = (e) => {
    const { value } = e.target    

    let selectedProsMarkets = []

    if(value.length === dailyProsData.filter(({Label}) => Label !== 'ALL').length) {
      setSelectedMarkets(['ALL'])
      setChecked(dailyProsData.map(({Label}) => Label))
      setSelectedDailyProsData(dailyProsData.filter(({Label}) => Label === 'ALL'))            
    } else if (
      value.length > 1 && selectedMarkets.includes('ALL')
      || value.length >= 1 && value.includes('ALL') === false
      ) {
        if(value.filter(item => item !== 'ALL').length < 2) {        
        let filter = value.filter(item => item !== 'ALL')[0]
        
        setSelectedDailyProsData(dailyProsData.filter(({Label}) => Label === filter))        
        } else {        
        selectedProsMarkets.push(dailyProsData.filter(({Label}) => value.includes(Label) && Label !== 'ALL'))        
        
        let data = []
        let dataObj = {}
        let dataCategory = []
        let dataLocation = []
        let dataCustPros = []
        let dataListType = []
        let dataOverall = []
        let dataWeekly = []
        
        for(let i = 0; i < selectedProsMarkets[0].length; i++) {
          if(selectedType === 'market') {
            dataCategory.push(selectedProsMarkets[0][i]['Data_Category'])            
            dataLocation.push(selectedProsMarkets[0][i]['Data_Location'])
            dataListType.push(selectedProsMarkets[0][i]['Data_ListType'])
            dataOverall.push(selectedProsMarkets[0][i]['Data_Overall'][0])
            dataWeekly.push(selectedProsMarkets[0][i]['Data_Weekly'])
          } else {
            dataCategory.push(selectedProsMarkets[0][i]['Data_Category'])            
            dataListType.push(selectedProsMarkets[0][i]['Data_ListType'])
            dataOverall.push(selectedProsMarkets[0][i]['Data_Overall'][0])
            dataWeekly.push(selectedProsMarkets[0][i]['Data_Weekly'])
          }
        }      
        
        // LOCATION DATA FOR MULTIPLE MARKET SELECTS
        let locationNames = dataLocation.length > 0 ? dataLocation[0].map(({Label}) => Label) : []
        const locationsArr = []
        
        if(locationNames.length > 0) {
          for(let i = 0; i < locationNames.length; i++) {
            const locationObj = {}
            locationObj['label'] = locationNames[i]
            locationObj['Data'] = []
            for(let j = 0; j < dataLocation.length; j++) {
              locationObj['Data'].push(dataLocation[j].filter(({Label}) => Label === locationNames[i])[0])
            }
            locationsArr.push(locationObj)
          }
        }
        const locationTotal = [locationsArr.map(({Data}) => Data.reduce((a,b) => {
          for(const prop in b) {
            if(a.hasOwnProperty(prop) && prop !== 'Label') a[prop] += b[prop]
            else a[prop] = b[prop]
          }
          return a
        }, {}))]
        
        // CATEGORY DATA FOR MULTIPLE MARKET SELECTS
        let categoryNames = dataCategory[0].map(({Label}) => Label)
        const categoryArr = []
        
        for(let i = 0; i < categoryNames.length; i++) {
          const categoryObj = {}
          categoryObj['Data'] = []
          for(let j = 0; j < dataCategory.length; j++) {
            categoryObj['Data'].push(dataCategory[j].filter(({Label}) => Label === categoryNames[i])[0])
          }
          categoryArr.push(categoryObj)
        }
        const categoryTotal = [categoryArr.map(({Data}) => Data.reduce((a,b) => {
          for(const prop in b) {
            if(a.hasOwnProperty(prop) && prop !== 'Label') a[prop] += b[prop]
            else a[prop] = b[prop]
          }
          return a
        }, {}))]
        
        // LIST TYPE DATA FOR MULTIPLE MARKET SELECTS
        let listNames = dataListType[0].map(({Label}) => Label)
        let listArr = []
        for(let i = 0; i < listNames.length; i++) {
          const listObj = {}
          listObj['Data'] = []
          for(let j = 0; j < dataListType.length; j++) {
            listObj['Data'].push(dataListType[j].filter(({Label}) => Label === listNames[i])[0])
          }
          listArr.push(listObj)
        }
        const listTotal = [listArr.map(({Data}) => Data.reduce((a,b) => {
          for(const prop in b) {
            if(a.hasOwnProperty(prop) && prop !== 'Label') a[prop] += b[prop]
            else a[prop] = b[prop]
          }
          return a
        }, {}))]

        // WEEKLY DATA FOR MULTIPLE MARKET SELECTS
        let weeklyLabels = dataWeekly[0].map(({Label}) => Label)
        let weeklyArr = []
        for(let i = 0; i < weeklyLabels.length; i++) {
          const weeklyObj = {}
          weeklyObj['Data'] = []
          for(let j = 0; j < dataWeekly.length; j++) {
            weeklyObj['Data'].push(dataWeekly[j].filter(({Label}) => Label === weeklyLabels[i])[0])
          }
          weeklyArr.push(weeklyObj)
        }
        const weeklyTotal = [weeklyArr.map(({Data}) => Data.reduce((a,b) => {
          for(const prop in b) {
            if(a.hasOwnProperty(prop) && prop !== 'Label') a[prop] += b[prop]
            else a[prop] = b[prop]
          }
          return a
        }, {}))]
        
        // OVERALL DATA FOR MULTIPLE MARKET SELECTS
        const dataOverallTotal = [dataOverall.reduce((a,b) => {
          for(const prop in b) {
            if(a.hasOwnProperty(prop)) a[prop] += b[prop]
            else a[prop] = b[prop]
          }
          return a
        }, {})]
        
        dataObj['Data_Overall'] = dataOverallTotal        
        dataObj['Data_Location'] = locationTotal[0]
        dataObj['Data_Category'] = categoryTotal[0]
        dataObj['Data_ListType'] = listTotal[0]
        dataObj['Data_Weekly'] = weeklyTotal[0]
        data.push(dataObj)  

          setSelectedDailyProsData(data)
        }

        setChecked(value.filter(item => item !== 'ALL'))
        setSelectedMarkets(value.filter(item => item !== 'ALL'))        
        } else {          
          setChecked(dailyProsData.map(({Label}) => Label))
          setSelectedMarkets(['ALL'])
          setSelectedDailyProsData(dailyProsData.filter(({Label}) => Label === 'ALL'))        
          }
        }        
        
        const renderMarketOptions = () => (
          dailyProsData.map(({Key, Label}) => (
            <MenuItem
            key={Key}
            value={Label}
        style={selectedMarkets.indexOf(Label) === -1 ? {} : {fontWeight: 'bold'}}
      >
        <div>
          <Checkbox 
            edge='start'
            size='small'
            checked={checked.indexOf(Label) !== -1}
            disableRipple
          />
          <Typography variant='caption' style={{width: 200}}>
            {Label === 'ALL' ? `${Label.toUpperCase()} ${selectedType.toUpperCase()}S` : Label.toUpperCase()}
          </Typography>
        </div>
      </MenuItem>
    ))
  )
  
  if(!selectedDailyProsData) return <CircularProgress/>
  
  return <div>
    <div>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div>
          <Typography className={classes.pageTitle}>
            <RiUserSearchFill className={classes.pageIcon}/>
            DAILY PROSPECTING                        
          </Typography>
        </div>
        <div style={{marginTop: '30px', textAlign: 'right'}}>
         {db === 'DHC' || compID === '273' || compID === '304' || corpID === 265 || db === 'HARRYDAVID' || db === 'WOLFERMANS' ?
            <Typography variant="caption">
                Showing <span onClick={handleMailingTypeOpen} style={{display: "inline-flex"}}>{`${mailingType.toUpperCase()} MAILINGS`}
                {mailingType.toUpperCase() === "COMPLETED" ?
                  <StyledTooltip
                    title={<Typography variant="caption">Our reporting is based on the expected in-home date of the mailed piece, which typically <span style={{fontWeight: "bold", color: "#000"}}>expires</span> within 14-30 days <strong style={{color: "#000"}}>(based on your business rules)</strong>. First-class mail takes 4 days to print and mail, while standard mail takes 10 or more days. Allow up to 4 days after the last transaction date <strong style={{color: "#000"}}>we have received</strong> to see results from that day's transactions. Results from a specific day's mailing may take up to 45 days to appear if you transmit data weekly and the expiration period is 30 days.</Typography>}
                    placement="bottom-end"
                  >
                    <InfoOutlined style={{fontSize: "17px"}}/>
                  </StyledTooltip>
                :
                  null
                }
                </span> from:
            </Typography>
          :
            <Typography variant="caption">
                Showing <span style={{color: '#001d84', display: "inline-flex"}}>{`${mailingType.toUpperCase()} MAILINGS`}
                <StyledTooltip
                  title={<Typography variant="caption">Our reporting is based on the expected in-home date of the mailed piece, which typically <span style={{fontWeight: "bold", color: "#000"}}>expires</span> within 14-30 days <strong style={{color: "#000"}}>(based on your business rules)</strong>. First-class mail takes 4 days to print and mail, while standard mail takes 10 or more days. Allow up to 4 days after the last transaction date <strong style={{color: "#000"}}>we have received</strong> to see results from that day's transactions. Results from a specific day's mailing may take up to 45 days to appear if you transmit data weekly and the expiration period is 30 days.</Typography>}
                  placement="bottom-end"                    
                >
                  <InfoOutlined style={{fontSize: "17px"}}/>
                </StyledTooltip>
                </span> from:
            </Typography>
          }
          <div style={{display: 'flex', marginTop: '2px'}}>
            <Select 
              variant='outlined'
              value={selectedType}
              onChange={handleSelectedType}
              input={<StyledInput style={{width: '150px'}}/>}
              style={{marginRight: '15px', textAlign: 'left'}}
            >
              <MenuItem value={'market'}>            
                <div style={{padding: '5px 15px 0px 15px'}}>
                  <Typography variant='caption'>BY MARKET</Typography>
                </div>
              </MenuItem>
              <MenuItem value={'location'}>            
                <div style={{padding: '5px 15px 0px 15px'}}>
                  <Typography variant='caption'>BY LOCATION</Typography>
                </div>
              </MenuItem>
            </Select>

            <div style={{marginRight: '15px', textAlign: 'left'}}>
              <Select
                multiple
                value={selectedMarkets}
                onChange={handleMarketChange}
                input={<StyledInput style={{minWidth: 200, maxWidth: 350}} />}
                renderValue={selected => (
                  selected.length > 1 ?
                    <div style={{padding: '5px 0px 0px 20px'}}>
                      <Typography variant='caption'>{`${selected.length} ${selectedType.toUpperCase()}S`} SELECTED</Typography>
                    </div>
                  :
                    <div style={{padding: '5px 0px 0px 20px'}}>
                      {selected.map(value => 
                        <Typography variant='caption'>{value === 'ALL' ? `${value.toUpperCase()} ${selectedType.toUpperCase()}S` : value}</Typography>
                      )}
                    </div>
                )}

              >
                {renderMarketOptions()}
              </Select>
            </div>          

            <div style={{width: 500}}>
              <DayPicker 
                startDate={dates.startDate}
                endDate={dates.endDate}
                handleDateRange={handleDateRange}
                handleSliderDateChange={handleSliderDateChange}
                handleDateClear={handleDateClear}
              />
            </div>
        </div>
        
        </div>        
        
      </div>
      <Stats data={getStatsData()}/>      
      <br/>
      <br/>      
      <CategoriesTable 
        data={selectedDailyProsData.length > 0 ? getTableData(selectedDailyProsData[0].Data_Category) : []}
      />
      <br/>
      <br/>
      <RoiCharts 
        chartsData={selectedDailyProsData.length > 0 ? [] : []}
        categoriesChartData={selectedDailyProsData.length > 0 ? getTableData(selectedDailyProsData[0].Data_Category) : []}
        listTypeChartData={selectedDailyProsData.length > 0 ? getTableData(selectedDailyProsData[0].Data_ListType) : []}
        labels={['Total Spend', 'Incremental Sales']}
        labels2={['Customer Responses', 'Prospect Responses']}
        weeklyData={[]}
        pageName='dailypros'
      />
      <br/>
      <br/>      
      <TrigLocationsTable data={selectedDailyProsData.length > 0 ? getTableData(selectedDailyProsData[0].Data_Location) : []}/>
    </div>
  </div>
}