import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import {
  Typography,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    width: '100%',
    marginRight: 20,
  },
  title: {
    marginBottom: '15px',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  header: {
    color: 'rgba(255, 255, 255, 1.0)',
    fontWeight: 700,
  },
  details: {
    alignItems: 'right'
  },
  column: {
    flexBasis: '50%'
  },
  total: {
    fontWeight: 700,
    color: 'rgba(92, 92, 92)',
    flexBasis: '50%'
  },
  totalDark: {
    fontWeight: 700,
    color: 'rgba(255, 255, 255)',
    flexBasis: '50%'
  }
}))

const getTableHeaders = (roasFlag) => {    
    if(roasFlag === 1) return [
      {name: 'Type', alignment: 'left', sortBy: 'Label'},
      {name: 'Spend', alignment: 'right', sortBy: 'Cost'},
      {name: 'Sales', alignment: 'right', sortBy: 'Sales'},
      {name: 'Mailed', alignment: 'right', sortBy: 'Mailed'},
      {name: 'Responses', alignment: 'right', sortBy: 'Responses'},
      {name: 'Response Rate', alignment: 'right', sortBy: 'Res_Rate'},
      {name: 'Holdout Count', alignment: 'right', sortBy: 'HO_Count'},
      {name: 'Holdout Sales', alignment: 'right', sortBy: 'HO_Sales'},
      {name: 'Holdout Responses', alignment: 'right', sortBy: 'HO_Resp'},
      {name: 'Holdout Response Rate', alignment: 'right', sortBy: 'HO_Res_Rate'},
      {name: 'Inc Response Rate', alignment: 'right', sortBy: 'ADJ_Res_Rate'},
      {name: 'Avg Ticket', alignment: 'right', sortBy: 'Avg_Ticket'},
      {name: 'ROAS', alignment: 'right', sortBy: 'Label'},
      {name: 'Inc ROAS', alignment: 'right', sortBy: 'Label',},
    ]
    else return [
      {name: 'Type', alignment: 'left', sortBy: 'Label'},
      {name: 'Spend', alignment: 'right', sortBy: 'Cost'},
      {name: 'Sales', alignment: 'right', sortBy: 'Sales'},
      {name: 'Mailed', alignment: 'right', sortBy: 'Mailed'},
      {name: 'Responses', alignment: 'right', sortBy: 'Responses'},
      {name: 'Response Rate', alignment: 'right', sortBy: 'Res_Rate'},
      {name: 'Holdout Sales', alignment: 'right', sortBy: 'HO_Count'},
      {name: 'Holdout Count', alignment: 'right', sortBy: 'HO_Res_Rate'},
      {name: 'Holdout Responses', alignment: 'right', sortBy: 'ADJ_Res_Rate'},
      {name: 'Holdout Response Rate', alignment: 'right', sortBy: 'Avg_Ticket'},
      {name: 'Inc Response Rate', alignment: 'right', sortBy: 'Label'},
      {name: 'Avg Ticket', alignment: 'right', sortBy: 'Label',},
    ]
  }

function TriggerTypes(props) {  
  const classes = useStyles()
  const totalRR = props.data.map(item => item.total.Responses).reduce((a,b) => a + b, 0) / props.data.map(item => item.total.Mailed).reduce((a,b) => a + b, 0)
  const holdoutRR = props.data.map(item => item.total.HO_Resp).reduce((a,b) => a + b, 0) / props.data.map(item => item.total.HO_Count).reduce((a,b) => a + b, 0)
  const tableHeaders = getTableHeaders(props.roasFlag)
  return(
    <div>
      <Typography className={classes.title}>TRIGGER TYPES</Typography>
      <Paper>
        <Table>
          <TableHead style={{backgroundColor: '#0e85d3'}}>
            <TableRow>
            {tableHeaders.map(({name, alignment, sortBy}, i) => (
                    <TableCell className={classes.header} align={alignment} key={`name-${i}`}>
                      <Typography variant="caption" className={classes.header}>{name}</Typography>
                    </TableCell>
                  ))}
            </TableRow>
          </TableHead>
          <TableBody>
              {props.data.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell><Typography variant="caption">{(row.label).toUpperCase()}</Typography></TableCell>
                    <TableCell align="right"><Typography variant="caption">${Math.round(row.total.Cost).toLocaleString('en')}</Typography></TableCell>
                    <TableCell align="right"><Typography variant="caption">${Math.round(row.total.Sales).toLocaleString('en')}</Typography></TableCell>
                    <TableCell align="right"><Typography variant="caption">{Math.round(row.total.Mailed).toLocaleString('en')}</Typography></TableCell>
                    <TableCell align="right"><Typography variant="caption">{Math.round(row.total.Responses).toLocaleString('en')}</Typography></TableCell>
                    <TableCell align="right"><Typography variant="caption">{row.total.Mailed > 0 ? (row.total.Responses / row.total.Mailed * 100).toFixed(1).toLocaleString('en') : 0}%</Typography></TableCell>
                    <TableCell align="right"><Typography variant="caption">{props.roasFlag === 1 ? Math.round(row.total.HO_Count).toLocaleString("en") : '$' + Math.round(row.total.HO_Sales).toLocaleString('en')}</Typography></TableCell>
                    <TableCell align="right" style={{display: `${props.roasFlag === 1 ? "" : "none"}`}}><Typography variant="caption">{`$${Math.round(row.total.HO_Sales).toLocaleString('en')}`}</Typography></TableCell>
                    <TableCell align="right" style={{display: `${props.roasFlag === 1 ? "" : "none"}`}}><Typography variant="caption">{`${Math.round(row.total.HO_Resp).toLocaleString('en')}`}</Typography></TableCell>
                    <TableCell align="right"><Typography variant="caption">{props.roasFlag === 1 ? row.total['HO_Res_Rate'] > 0 ? (row.total['HO_Res_Rate'] * 100).toFixed(2) + '%' : '0.00%' : Math.round(row.total['HO_Count']).toLocaleString('en')}</Typography></TableCell>
                    <TableCell align="right"><Typography variant="caption">{props.roasFlag === 1 ? row.total['ADJ_Res_Rate'] > 0 ? (row.total['ADJ_Res_Rate'] * 100).toFixed(2) + '%' : '0.00%' : Math.round(row.total['HO_Resp']).toLocaleString('en')}</Typography></TableCell>
                    <TableCell align="right"><Typography variant="caption">{props.roasFlag === 1 ? row.total['Responses'] > 0 ? "$" + Math.round(row.total['Sales']/row.total['Responses']).toLocaleString('en') : '$0' : (row.total['HO_Res_Rate'] * 100).toFixed(2) + "%"}</Typography></TableCell>
                    <TableCell align="right"><Typography variant="caption">{props.roasFlag === 1 ? row.total.Cost > 0 ? "$" + (row.total.Sales / row.total.Cost).toLocaleString("en", {minimumFractionDigits: 2, maximumFractionDigits: 2}) : "$0" : ((row.total.Responses / row.total.Mailed) - (row.total.HO_Resp / row.total.HO_Count)) > 0 ? (((row.total.Responses / row.total.Mailed) - (row.total.HO_Resp / row.total.HO_Count)) * 100).toFixed(2) + "%" : "0%"}</Typography></TableCell>                                                                         
                    <TableCell align="right"><Typography variant="caption">${props.roasFlag === 1 ? (row.total.Responses / row.total.Mailed) - (row.total.HO_Resp / row.total.HO_Count) > 0 ? ((row.total.Sales / (row.total.Responses/row.total.Mailed)) * (row.total.Responses / row.total.Mailed - (row.total.HO_Resp / row.total.HO_Count)) / row.total.Cost).toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : 0 : row.total.Responses > 0 ? Math.round(row.total.Sales / row.total.Responses).toLocaleString('en') : 0}</Typography></TableCell>                    
                  </TableRow>
                ))}
            <TableRow>
            <TableCell className={props.darkMode ? classes.totalDark : classes.total}><Typography variant="caption" className={props.darkMode ? classes.totalDark : classes.total}>{'total'.toUpperCase()}</Typography></TableCell>
                  <TableCell className={props.darkMode ? classes.totalDark : classes.total} align="right"><Typography variant="caption" className={props.darkMode ? classes.totalDark : classes.total}>${props.data ? Math.round(props.data.map(item => item.total.Cost).reduce((a,b) => a + b, 0)).toLocaleString('en') : 0}</Typography></TableCell>
                  <TableCell className={props.darkMode ? classes.totalDark : classes.total} align="right"><Typography variant="caption" className={props.darkMode ? classes.totalDark : classes.total}>${props.data ? Math.round(props.data.map(item => item.total.Sales).reduce((a,b) => a + b, 0)).toLocaleString('en') : 0}</Typography></TableCell>
                  <TableCell className={props.darkMode ? classes.totalDark : classes.total} align="right"><Typography variant="caption" className={props.darkMode ? classes.totalDark : classes.total}>{props.data ? Math.round(props.data.map(item => item.total.Mailed).reduce((a,b) => a + b, 0)).toLocaleString('en') : 0}</Typography></TableCell>
                  <TableCell className={props.darkMode ? classes.totalDark : classes.total} align="right"><Typography variant="caption" className={props.darkMode ? classes.totalDark : classes.total}>{props.data ? Math.round(props.data.map(item => item.total.Responses).reduce((a,b) => a + b, 0)).toLocaleString('en') : 0}</Typography></TableCell>
                  <TableCell className={props.darkMode ? classes.totalDark : classes.total} align="right"><Typography variant="caption" className={props.darkMode ? classes.totalDark : classes.total}>{props.data ? (totalRR * 100).toFixed(2) : 0}%</Typography></TableCell>
                  <TableCell className={props.darkMode ? classes.totalDark : classes.total} align="right"><Typography variant="caption" className={props.darkMode ? classes.totalDark : classes.total}>{props.roasFlag === 1 ? Math.round(props.data.map(item => item.total.HO_Count).reduce((a,b) => a + b, 0)).toLocaleString("en") :  props.data ? '$' + Math.round(props.data.map(item => item.total.HO_Sales).reduce((a,b) => a + b, 0)).toLocaleString('en') : 0}</Typography></TableCell>
                  <TableCell align="right" style={{display: `${props.roasFlag === 1 ? "" : "none"}`}}><Typography variant="caption" className={classes.total}>{props.data ? `$${Math.round(props.data.map(item => item.total.HO_Sales).reduce((a,b) => a + b, 0)).toLocaleString('en')}` : "$" + 0}</Typography></TableCell>
                  <TableCell align="right" style={{display: `${props.roasFlag === 1 ? "" : "none"}`}}><Typography variant="caption" className={classes.total}>{props.data ? `${Math.round(props.data.map(item => item.total.HO_Resp).reduce((a,b) => a + b, 0)).toLocaleString('en')}` : 0}</Typography></TableCell>
                  <TableCell className={props.darkMode ? classes.totalDark : classes.total} align="right"><Typography variant="caption" className={props.darkMode ? classes.totalDark : classes.total}>{props.data ? props.roasFlag === 1 ? (props.data.map(item => item.total.HO_Resp).reduce((a,b) => a + b, 0) / props.data.map(item => item.total.HO_Count).reduce((a,b) => a + b, 0) * 100).toFixed(2) + "%" : Math.round(props.data.map(item => item.total.HO_Count).reduce((a,b) => a + b, 0)).toLocaleString('en') : 0}</Typography></TableCell>
                  <TableCell className={props.darkMode ? classes.totalDark : classes.total} align="right"><Typography variant="caption" className={props.darkMode ? classes.totalDark : classes.total}>{props.data ? props.roasFlag === 1 ? totalRR - holdoutRR > 0 ? ((totalRR - holdoutRR) * 100).toFixed(2) + "%" : "0%" :  Math.round(props.data.map(item => item.total.HO_Resp).reduce((a,b) => a + b, 0)).toLocaleString('en') : 0}</Typography></TableCell>
                  <TableCell className={props.darkMode ? classes.totalDark : classes.total} align="right"><Typography variant="caption" className={props.darkMode ? classes.totalDark : classes.total}>{props.data ? props.roasFlag === 1 ? "$" + Math.round(props.data.map(({total}) => total.Sales).reduce((a,b) => a + b, 0) / props.data.map(({total}) => total.Responses).reduce((a,b) => a + b, 0)).toLocaleString("en") : (holdoutRR * 100).toFixed(2) + "%" : "0%"}</Typography></TableCell>
                  <TableCell className={props.darkMode ? classes.totalDark : classes.total} align="right"><Typography variant="caption" className={props.darkMode ? classes.totalDark : classes.total}>{props.data ? props.roasFlag === 1 ? "$" + (props.data.map(({total}) => total.Sales).reduce((a,b) => a + b, 0) / props.data.map(({total}) => total.Cost).reduce((a,b) => a + b, 0)).toLocaleString("en", {minimumFractionDigits: 2, maximumFractionDigits: 2}) : ((totalRR - holdoutRR) * 100).toFixed(2) + "%" : "0%"}</Typography></TableCell>
                  <TableCell className={props.darkMode ? classes.totalDark : classes.total} align="right"><Typography variant="caption" className={props.darkMode ? classes.totalDark : classes.total}>${props.data ? props.roasFlag === 1 ? totalRR - holdoutRR > 0 ? (props.data.map(({total}) => total.Sales).reduce((a,b) => a + b, 0) / totalRR * (totalRR - holdoutRR) / props.data.map(({total}) => total.Cost).reduce((a,b) => a + b, 0)).toLocaleString("en", {minimumFractionDigits: 2, maximumFractionDigits: 2}) : 0 : Math.round(props.data.map(item => item.total.Sales).reduce((a,b) => a + b, 0) / props.data.map(item => item.total.Responses).reduce((a,b) => a + b, 0)).toLocaleString('en') : 0}</Typography></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </div>
  )
}

export default TriggerTypes
